h2 {
  @extend %font-semibold;
  font-size: 28px;
  color: $dark;
  @media screen {
    font-size: 16px;
    line-height: 1.54;
  }
}

.row {
  display: flex;
  @include gap(column, 30px);
}

.icon {
  font-size: 1em;
  height: 1em;
  width: 1em;
  fill: currentColor;
}
