@font-face {
  font-family: 'Raleway Regular';
  src: url("@/assets/fonts/Raleway/Raleway-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Medium';
  src: url("@/assets/fonts/Raleway/Raleway-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway SemiBold';
  src: url("@/assets/fonts/Raleway/Raleway-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Bold';
  src: url("@/assets/fonts/Raleway/Raleway-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway ExtraBold';
  src: url("@/assets/fonts/Raleway/Raleway-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}
