
.top-section-collapse {
  &-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__label {
      font-family: "Raleway SemiBold", serif;
      font-size: 16px;
      color: #2D2E2F;
    }
  }
}
