.assignments {
  @extend %font-regular;
  $padding: 20px;

  .el-collapse {
    border: none;

    &-item {
      &__header {
        border: none;
      }

      &__wrap {
        border: none;
      }

      &__arrow {
        display: none;
      }

      &.is-active {
        .assignments__icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__wrapper {
    &:not(:last-child :empty) {
      margin-bottom: 24px;
      @include media(768) {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-family: "Raleway Bold", serif;
    font-size: 40px;
    color: $dark;
    margin-bottom: 32px;
  }

  &__col {
    flex: 1;
  }

  &__title-row {
    margin-bottom: 27px !important;

    h2 {
      @include media(768) {
        transition: inherit;
        text-align: center;
        color: $second-light;
      }
    }

    @include media(768) {
      margin: 0px - 15px;
      width: calc(100% + 30px);
      .assignments {
        &__col {
          padding-bottom: 21px;
          @include transition;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $second;
            transition: inherit;
            opacity: 0;
            visibility: hidden;
          }

          &.active {
            &:before {
              opacity: 1;
              visibility: visible;
            }

            h2 {
              color: $dark;
            }
          }
        }
      }
    }
  }

  &__row {
    flex-direction: row-reverse;
    @include media(768) {
      .assignments {
        &__col {
          padding-bottom: 21px;
          position: absolute;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          width: 100%;
          flex: auto;
          left: 0;
          @include transition(0.3, ease-in-out, #{opacity, visibility});

          &.active {
            z-index: 2;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &__title-row,
  &__row {
    @include media(768) {
      @include gap(column, 0);
    }
  }

  &__cart {
    border: 1px solid $silver;
    border-radius: 10px;
    padding: $padding 0;

    @include media(768) {
      border: 0;
    }

    &.open {
      .assignments {
        &__text {

          span {
            @include mediamin(767) {
              max-width: 0;
              visibility: hidden;
              opacity: 0;
              height: 1px;
              margin: -1px;
              border: 0;
              padding: 0;
              white-space: nowrap;
            }
          }
        }

        &__icon {
          transform: scale(1, -1);
        }

        &__cart-bottom {
          max-height: 9999px;
        }
      }
    }
  }

  &__cart-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $padding;
    @include media(768) {
      padding: 15px;
      margin-left: 15px;
      margin-right: 15px;
      border: 1px solid $silver;
      border-radius: 10px;
    }
  }

  &__code {
    display: flex;
    align-items: center;
  }

  &__image {
    @include size(52px);
    background: $silver;
    margin-right: 24px;
    border-radius: 50%;
  }

  &__text {
    display: flex;
    align-items: center;
    a {
      margin-right: 30px;
    }
    font-size: 20px;
    line-height: 0.8;
    text-transform: uppercase;
    @extend %font-medium;
    @include media(768) {
      font-size: 16px;
    }

    span {
      text-transform: none;
      max-width: 200px;
      display: inline-block;
      @include transition(all, 0.5, ease-in-out);
      //overflow: hidden;
      white-space: nowrap;
      float: left;
      margin-right: 5px;
      opacity: 1;
    }
  }

  &__icon {
    @include size(28px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark;
    margin-left: auto;
    @include transition;

    .icon {
      font-size: 16px;
    }
  }

  &__cart-item {
    padding: 20px $padding;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $silver;
    justify-content: space-between;
    @include gap(column, 10px);
    @include media(768) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 24px;
    }
  }

  &__type {
    font-size: 16px;
    @extend %font-medium;
  }

  &__value {
    @extend %font-semibold;
    font-size: 16px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }

  //&__type,
  //&__value {
  //  flex: 1;
  //}
  &__cart-bottom {
    .row {
      padding: 20px $padding 0;
      @include media(768) {
        padding: 24px 15px 0;
        flex-wrap: wrap;
        @include gap(row, 20px);
      }

      .el-button {
        margin-left: 0;
        @include media(768) {
          width: 100%;
        }
      }
    }
  }
}
