.responsive-table {
  &-header {
    align-items: center;
    margin-top: 30px;

    &__item {
      margin-right: 15px;
      @media(max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &__title {
      font-family: "Raleway Bold", serif;
      font-size: 28px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media(max-width: 768px) {
        font-size: 24px;
      }

      &-button {
        font-family: "Raleway Bold", serif;
        font-size: 16px;
        color: #000000;
        margin-left: 15px;
        height: 48px;
        @media(max-width: 768px) {
          margin-left: 0;
          margin-top: 15px;
          width: 100%;
        }
      }

      &-team {
        font-family: "Raleway Regular", serif;
        font-size: 28px;
        color: #000000;
        @media(max-width: 768px) {
          font-size: 24px;
        }
      }
    }
  }

  table {
    margin: 30px 0 0 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    @media (max-width: 768px) {
      border: 0;
    }

    thead {
      @media (max-width: 768px) {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }

    tr {
      @media(max-width: 768px) {
        border: 1px solid #DDD;
        display: block;
        margin-bottom: 40px;
        padding: 20px;
      }
    }

    th {
      font-family: "Raleway Bold", serif;
      font-size: 12px;
      text-transform: uppercase;
      color: #AFAFAF;
      text-align: center;
      padding-bottom: 20px;
    }

    td {
      font-family: "Raleway Regular", serif;
      font-size: 16px;
      color: #161616;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:last-child) {
        padding-bottom: 40px;
        @media(max-width: 768px) {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
        }
      }

      @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before {
          content: attr(data-label);
          float: left;
          font-family: "Raleway Bold", serif;
          font-size: 12px;
          text-transform: uppercase;
          color: #AFAFAF;
          text-align: center;
        }
      }
    }
  }

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 20px 0;

    h1 {
      font-family: "Raleway Bold", serif;
      font-size: 20px;
      color: #000000;
    }
  }

  &-actions {
    cursor: pointer;
  }

  &-status {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin: 0 auto;

    @media(max-width: 768px) {
      margin: 0;
    }

    &.assigned {
      background: #26C689;
    }

    &.notAssigned {
      background: #FF5F00;
    }

    &.waitingConfirmation {
      background: #FFC263;
    }
  }

  &-filter {
    margin: 30px 0 0 0;
    @media (min-width: 1200px) {
      max-width: 340px;
    }
  }
}

.reports-table {
  margin: 50px 0 0 0;

  .el-table__header {
    width: 100% !important;

    th {
      border: none !important;
      padding-bottom: 25px;

      .cell {
        font-family: "Raleway Bold", serif;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        word-break: keep-all;

        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
    }
  }

  .el-table__body {
    width: 100% !important;

    tr {
      td {
        border: none !important;
        padding: 20px 0;

        .cell {
          font-family: "Raleway Regular", serif;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #161616;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .el-table__footer {
    width: 100% !important;

    tr {
      td {
        background: none;
        padding: 20px 0;

        .cell {
          font-family: "Raleway Bold", serif;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }
  }
}
