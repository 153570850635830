.el-dropdown-menu {
  padding: 0;

  &__item {
    font-family: "Raleway Regular", serif;
    font-size: 16px;
    color: #000000;
    padding: 9px 17px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    }

    &:not(.is-disabled):focus {
      background-color: #F6F7F9;
      color: #000000;
    }
  }
}
