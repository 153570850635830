.vue-image-crop-upload {
  .vicp-wrap {
    @media(max-width: 600px) {
      width: 100% !important;
      height: auto !important;
      padding: 40px 5px !important;
    }

    .vicp-img-container {
      @media(max-width: 600px) {
        margin: 0 auto !important;
      }
    }

    .vicp-crop-right {
      @media(max-width: 600px) {
        margin: 0 auto !important;
      }
    }

    .vicp-range {
      @media(max-width: 600px) {
        margin: 30px auto !important;
      }
    }

    .vicp-crop {
      @media(max-width: 600px) {
        display: flex !important;
        flex-direction: column !important;
      }
    }
  }
}
