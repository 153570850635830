@import "helpers/reset";

@import "element-ui/override";

@import "helpers/fonts";
@import "helpers/spacing-helpers";
@import "helpers/directives";
@import "helpers/global";
@import "components/form";
@import "components/table";

@import "elements/buttons";
@import "elements/popup";
@import "elements/inputs";
@import "elements/links";
@import "elements/uploader";
@import "elements/dropdown-menu";
@import "elements/popper";
@import "elements/message";
@import "page/assigments";

body {
  font-family: "Raleway Regular", serif;
}

.el-tabs__item,
.el-checkbox__label,
.el-tabs__item {
  font-family: "Raleway Medium", serif;
}

.open-qr {
  color: #ffad4d;
}

.el-message {
  .el-message__content {
    line-height: 1.2 !important;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
}

.top-bar-pooling .el-dropdown{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  background: #FF8A00;
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 48px;
 

  span {
    outline: none;
    color: #fff;
    cursor: pointer;
    width: 100%;
    height: auto;
    text-align: center;
  }
}
