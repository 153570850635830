.go-back-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #000000;
  border-radius: 8px;
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.reset-password-link {
  font-size: 14px;
  font-family: "Raleway Regular", serif;
  text-decoration: underline;
  color: #FF8A00;

  &:hover {
    color: #ffa133;
  }
}

.terms-text {
  font-size: 14px;
  font-family: "Raleway Medium", serif;
  text-align: center;

  a {
    text-decoration: none;
    color: #FF8A00;
  }
}
