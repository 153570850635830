.el-popper {
  &.is-light {
    box-shadow: none;
    border: none;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
  }

  &__arrow {
    display: none;
  }
}
