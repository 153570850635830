.el-form-item {
  margin: 0 0 20px 0;

  &__label {
    font-family: "Raleway SemiBold", serif;
    font-size: 16px;
    color: #2D2E2F;
    padding: 0 !important;
  }

  .password-description {
    font-family: "Raleway Regular", serif;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  &__error {
    font-family: "Raleway Regular", serif;
  }
}

.organization-form-row__repeater,
.create-team-form-row__repeater,
.update-team-form-row__repeater {
  .el-form-item {
    &__error {
      margin-top: -20px;
    }
  }
}

