.el-input__wrapper {
  border: 1px solid #E3E5E6;
  border-radius: 8px;
  box-shadow: none;

  .el-input__inner {
    font-family: "Raleway Regular", serif;
    font-size: 16px;
    height: 50px;
    color: #000000 !important;

    &::placeholder {
      opacity: 0.5;
    }
  }
}

.el-switch {
  &__label {
    span {
      font-family: "Raleway SemiBold", serif;
      font-size: 16px;
      color: #2D2E2F;
    }
  }
}

.el-select, .el-input-number, .el-date-editor.el-input {
  width: 100%;
  height: auto;
}

.custom-phone-input {
  width: 100%;
  border: 1px solid #E3E5E6 !important;
  border-radius: 8px !important;
  height: 54px;

  input {
    border: none;
    background: transparent;
  }

  .vti__dropdown {
    font-size: 16px;
    color: #000000;

    &-list {
      width: 320px;
    }

    .vti__country-code {
      width: max-content;
      color: #2D2E2F;
      font-family: "Raleway Bold", serif;
      font-size: 16px;
    }
  }
}

.el-radio {
  &__label {
    font-family: "Raleway Medium", serif;
    font-size: 14px;
    color: #2D2E2F;
  }

  &.is-checked {
    .el-radio__label {
      font-family: "Raleway Bold", serif;
      color: #2D2E2F !important;
    }
  }

  &__inner {
    border: 2px solid #E3E5E5;
  }
}

.el-select {
  .el-input__inner {
    font-family: "Raleway Bold", serif;
    font-size: 16px;
    color: #000000;
  }

  &__popper {
    .el-select-dropdown__item {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      font-family: "Raleway Medium", serif;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: initial;

      &.selected {
        color: rgba(0, 0, 0, 0.6);
        background-color: #F6F7F9;
      }
    }
  }
}

