
.snackbar {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 15px;
  border: 1px solid #e3e5e5;
  border-radius: 8px;
  z-index: 999;
}

.add-home-screen {
  display: none;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  background: #ffffff;
  padding: 0 10px;
  box-shadow: 0 0 10px rgba(57, 20, 0, 0.04);
  border-top: 1px solid rgba(57, 20, 0, 0.04);
  z-index: 99;

  @media (max-width: 992px) {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }

  .app-description {
    h4 {
      font-weight: bold;
      line-height: 1.5;
    }

    p {
      font-size: 12px;
      color: #797878;
    }

    b {
      font-size: 12px;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    line-height: 1.25;
  }

  &__logo {
    height: 40px;
  }

  &__button {
    font-family: "Raleway Medium", serif;
    font-size: 16px;
    text-decoration: none;
    color: #ff8a00;
    margin-left: 15px;
    position: absolute;
    right: 70px;
    font-weight: bold;

  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
}
