$dark: #000000;
$silver: #F7F7F7;
$second: #FF8A00;
$second-light: #888888;

%font-regular {
  font-family: "Raleway Regular", sans-serif;
}

%font-medium {
  font-family: "Raleway Medium", sans-serif;
}

%font-semibold {
  font-family: 'Raleway SemiBold', sans-serif;
}

%font-bold {
  font-family: "Raleway Bold", serif;
}

@mixin media($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin mediamin($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

@mixin gap($property, $value) {
  @if $property == column {
    @supports (column-gap: $value) {
      column-gap: $value;
    }
    @supports not (column-gap: $value) {
      margin-right: calc(0px - (#{$value} / 2));
      margin-left: calc(0px - (#{$value} / 2));
      & > * {
        margin-right: calc(#{$value} / 2);
        margin-left: calc(#{$value} / 2);
      }
    }
  } @else if $property == row {
    @supports (row-gap: $value) {
      row-gap: $value;
    }
    @supports not (row-gap: $value) {
      & {
        margin-bottom: -$value;
      }
      & > * {
        margin-bottom: $value;
      }
    }
  } @else if $property == gap {
    @supports (gap: $value) {
      gap: $value;
    }
    @supports not (gap: $value) {
      margin-right: calc(0px - (#{$value} / 2));
      margin-left: calc(0px - (#{$value} / 2));
      margin-bottom: -$value;
      & > * {
        margin-right: calc(#{$value} / 2);
        margin-left: calc(#{$value} / 2);
        margin-bottom: $value;
      }
    }
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transition($property: all, $speed: 0.3, $time-function: ease) {
  transition: all .3s ease;
}

@mixin none() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}