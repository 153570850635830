
.body-section-collapse {
  &-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__label {
      font-family: "Raleway SemiBold", serif;
      font-size: 16px;
      color: #2D2E2F;
    }

    &-icon-uploader {
      height: 100%;
      width: 80px;
      display: block;

      &__avatar {
        width: 100px;
        height: 100px;
        display: block;
      }

      &__icon {
        font-size: 28px;
        color: #8c939d;
        width: 60px;
        height: 60px;
        text-align: center;
      }

      &:deep(.el-upload) {
        border: 1px dashed var(--el-border-color);
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);

        &:hover {
          border-color: var(--el-color-primary);
        }
      }
    }
  }
}
