@forward 'element-plus/theme-chalk/src/common/var.scss' with (
  $colors: (
    'primary': (
      'base': #FF8A00,
    ),
  )
);

@use "element-plus/theme-chalk/src/index.scss" as *;
@import 'element-plus/theme-chalk/display.css';
